<template>
  <div class="download-container">
    <div class="logo">
      <img src="../../assets/download/logo.png" alt="">
      <div class="title">云海易购APP</div>
    </div>
    <div class="red-packet">
      <img src="../../assets/download/redPackage.png" alt="">
      <div class="content">一个可以赚钱的APP，点击按钮下载APP！</div>
    </div>
    <div class="andorid">
      <van-button type="primary" color="#FFBB00" size="normal" round :icon="require('../../assets/download/andorid.png')" @click="downloadAPK">Android版下载</van-button>
    </div>
    <div class="ios">
      <van-button type="primary" color="#FFBB00" size="normal" round :icon="require('../../assets/download/apple.png')" @click="downloadIOS">IOS版下载</van-button>
    </div>
  </div>
</template>

<script>
import downloadConfig from '@/allConfig/index.js';
import {Toast} from 'vant';

const browser = {
    versions: (function () {
        const u = navigator.userAgent,
            app = navigator.appVersion;
        return {
            trident: u.indexOf("Trident") > -1, //IE内核
            presto: u.indexOf("Presto") > -1, //opera内核
            webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
            gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
            mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
            ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
            android: u.indexOf("Android") > -1 || u.indexOf("Adr") > -1, //android终端
            iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
            iPad: u.indexOf("iPad") > -1, //是否iPad
            webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
            weixin: u.indexOf("MicroMessenger") > -1, //是否微信 （2015-01-22新增）
            qq: u.match(/\sQQ/i) == " qq", //是否QQ
        };
    })(),
    language: (navigator.browserLanguage || navigator.language).toLowerCase(),
};
export default {
    data() {
        return {
          
        };
    },
    methods: {
      // 下载安卓包
      downloadAPK(){
        // 先判断是否在微信中
        this.isInWX()
        if(browser.versions.android) {
          window.location.href = downloadConfig.downloadUrl.android;
        } else if(browser.versions.ios || browser.versions.iPad || browser.versions.iPhone) {
          this.$dialog.confirm({
              title: '温馨提示',
              message: '您当前操作的手机是ios系统，是否继续下载？',
              showCancelButton: true
          }).then(() => {
            window.location.href = downloadConfig.downloadUrl.android;
          }).catch(() => {
            console.log('取消')
          });
        }
      },
      // 下载ios包
      downloadIOS(){
        // 先判断是否在微信中
        this.isInWX()
        // 判断是否在苹果浏览器中
        this.isInAppleWeb()
        if (browser.versions.ios || browser.versions.iPad || browser.versions.iPhone) {
          window.location.href = downloadConfig.downloadUrl.ios;
        } else if (browser.versions.android){
          this.$dialog.confirm({
              title: '温馨提示',
              message: '您当前操作的手机是android系统，是否继续下载？',
              showCancelButton: true
          }).then(() => {
            window.location.href = downloadConfig.downloadUrl.ios;
          }).catch(() => {
            console.log('取消')
          });
        }
      },
      // 判断是否在微信中
      isInWX(){
        // 是则提示要用浏览器打开
        if (browser.versions.mobile) {
          if (browser.versions.weixin || browser.versions.qq) {
            Toast.fail({
                message: "点击右上角，选择在浏览器打开",
                duration: 5000,
            });
            return
          }
        }
      },
      // 判断打开的是否是苹果浏览器
      isInAppleWeb(){
        if(!browser.versions.webKit){
          Toast('请使用苹果自带浏览器打开！')
          return
        }
      },
    },
    
    created() {
    },
    watch: {

    },
};
</script>
<style scoped lang='scss'>
@import "./index.scss";
</style>